// DraggableTable.tsx
import React, { FC, useState, useEffect } from 'react';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult
} from 'react-beautiful-dnd';
import { Box, Paper, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';

export interface Row {
    imageUrl: string;
    id: string;
    name: string;
    price: number;
    sort_index?: number;
    [key: string]: any;
}

interface DraggableTableProps {
    rows: Row[];
    onRowsUpdate: (updatedRows: Row[]) => void;
    productDetails: any[];
}

const RowContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    borderBottom: '1px solid #e0e0e0',
    backgroundColor: '#fff',
    transition: 'background-color 0.3s',
    '&:hover': {
        backgroundColor: '#f5f5f5'
    }
}));

const Cell = styled(Box)(({ theme }) => ({
    color: '#ADADAD',
    fontSize: '15px',
    fontFamily: 'sans-serif',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1)
}));

const DraggableTable: FC<DraggableTableProps> = ({
    rows,
    onRowsUpdate,
    productDetails
}) => {
    const [tableRows, setTableRows] = useState<Row[]>(rows);
    useEffect(() => {
        setTableRows(rows);
        onRowsUpdate(rows);
    }, [rows]);

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        const updatedRows = Array.from(tableRows);
        const [movedRow] = updatedRows.splice(result.source.index, 1);
        updatedRows.splice(result.destination.index, 0, movedRow);

        setTableRows(updatedRows);
        onRowsUpdate(updatedRows);

        // Log the latest order of items
    };

    const handlePriceChange = (index: number, newPrice: number) => {
        const updatedRows = [...tableRows];
        updatedRows[index].price = newPrice;
        setTableRows(updatedRows);
        onRowsUpdate(updatedRows);
    };
    const handleCategoryChange = (index: number, value: string) => {
        const updatedRows = [...tableRows];
        updatedRows[index]['product_detail_category_id'] = value;
        setTableRows(updatedRows);
        onRowsUpdate(updatedRows);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <Box
                        component={Paper}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        p={0}
                    >
                        {tableRows.length === 0 ? (
                            <Box py={2}>
                                <Typography align="center">
                                    No data available
                                </Typography>
                            </Box>
                        ) : (
                            tableRows.map((row, index) => (
                                <Draggable
                                    key={row.id}
                                    draggableId={row.id}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <RowContainer
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                ...provided.draggableProps
                                                    .style,
                                                backgroundColor:
                                                    snapshot.isDragging
                                                        ? '#f0f0f0'
                                                        : 'white'
                                            }}
                                        >
                                            <Cell>
                                                <img
                                                    src={row.imageUrl}
                                                    alt={row.name}
                                                    style={{
                                                        width: 70
                                                    }}
                                                />
                                            </Cell>
                                            <Cell style={{ width: '100px' }}>
                                                {row.id}
                                            </Cell>
                                            <Cell style={{ width: '100px' }}>
                                                {row.name}
                                            </Cell>
                                            <Cell>
                                                <div className="custom-select">
                                                    <select
                                                        id="product-details"
                                                        className="custom-select"
                                                        value={
                                                            row.product_detail_category_id
                                                        }
                                                        onChange={(e) =>
                                                            handleCategoryChange(
                                                                index,
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        <option value="-1">
                                                            None{' '}
                                                        </option>
                                                        {productDetails.map(
                                                            (product: any) => (
                                                                <option
                                                                    key={
                                                                        product.product_detail_category_id
                                                                    }
                                                                    value={
                                                                        product.product_detail_category_id
                                                                    }
                                                                >
                                                                    {
                                                                        product.name
                                                                    }
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </div>
                                            </Cell>
                                            <Cell sx={{ width: '200px' }}>
                                                <TextField
                                                    type="number"
                                                    value={row.price}
                                                    onChange={(e) =>
                                                        handlePriceChange(
                                                            index,
                                                            parseFloat(
                                                                e.target.value
                                                            )
                                                        )
                                                    }
                                                />
                                            </Cell>
                                        </RowContainer>
                                    )}
                                </Draggable>
                            ))
                        )}
                        {provided.placeholder}
                    </Box>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default DraggableTable;
