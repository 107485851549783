// App.tsx

import LoginPage from 'components/Login/LoginPage';
import Dashboard from 'components/Dashboard/Dashboard';
import Product from 'components/Dashboard/Product/List';
import Flyer from 'components/Dashboard/Flyer/List'
import Locations from 'components/Dashboard/Stores/List'
import Layout from 'components/Layout';
import RequireAuth from 'components/RequireAuth';
import {Routes, Route} from 'react-router-dom'
const App: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<Layout />} >
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<LoginPage />} />
        <Route  element={<RequireAuth />} >
          <Route element={<Dashboard />} >
            <Route path="/product" element={<Product />} />
            <Route path="/flyer" element={<Flyer />} />
            <Route path="/stores" element={<Locations />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
