import React from 'react';
import ReactDOM from 'react-dom/client';
import 'index.css';
import App from 'App';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    BrowserRouter
} from 'react-router-dom';
import { AuthProvider } from 'Context/AuthProvider';
import { SnackbarProvider } from 'notistack';
import { PrimeReactProvider } from 'primereact/api';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import { ConfirmDialog } from 'primereact/confirmdialog';
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <PrimeReactProvider>
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <AuthProvider>
                    <ConfirmDialog />
                    <Routes>
                        <Route path="/*" element={<App />} />
                    </Routes>
                </AuthProvider>
            </SnackbarProvider>
        </PrimeReactProvider>
    </BrowserRouter>

    // </React.StrictMode>
);
