import { useEffect, useState } from 'react';
import Subheader from '../Subheader';
import Table from '../Table/Table';
import Body from '../Body';
import Manage from './Manage';
import { _deleteLocation, _getLocations } from 'routes/routes';
import { formatLocationTable } from '../TableService';
import { useSnackbar } from 'notistack';
import useError from 'hooks/useError';
import { confirmDialog } from 'primereact/confirmdialog';
const List: React.FC = () => {
    const setError = useError();
    const { enqueueSnackbar } = useSnackbar();
    const [updateId, setUpdateId] = useState<number | null>(null);
    const [data, setData] = useState<any[]>([]);
    const [redrawTable, setRedrawTable] = useState<Boolean>(false);
    const [isOpenManage, setIsOpenManage] = useState(false);
    const [action, setAction] = useState<string>('New');
    useEffect(() => {
        if (!isOpenManage) {
            const fetchOptions = async () => {
                try {
                    const response = await _getLocations();
                    setData(response.data);
                } catch (err) {
                    setError(err);
                }
            };
            setUpdateId(null);
            fetchOptions();
        }
    }, [isOpenManage, redrawTable]);
    const { formattedColumns, formattedRows } = formatLocationTable(data);
    const handleEdit = (id: number) => {
        // Logic to handle editing a row based on the provided ID
        // enqueueSnackbar('Item edited ', { variant: 'success' });
        setUpdateId(id);
        setAction('Edit');
        setIsOpenManage(true);
    };
    const handleOpenmanage = () => {
        setAction('New');
        setIsOpenManage(true);
    };
    const handleCloseManage = () => {
        setUpdateId(null);
        setIsOpenManage(false);
    };
    // Function to handle deleting a row
    const handleDelete = async (id: number) => {
        const accept = async () => {
            try {
                await _deleteLocation(id);
                enqueueSnackbar('Item Deleted ', { variant: 'success' });
                setRedrawTable(!redrawTable);
            } catch (err) {
                setError(err);
            }
        };
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'accept',
            acceptClassName: 'p-button-danger',
            accept
        });
    };
    return (
        <div className="" style={{ height: '100%', overflow: 'auto' }}>
            {isOpenManage ? (
                <div style={{ height: '100%' }}>
                    <Manage
                        onClose={handleCloseManage}
                        onSave={() => setRedrawTable(!redrawTable)}
                        type={action}
                        updateId={updateId}
                    />
                </div>
            ) : (
                <div style={{ height: '100%' }}>
                    <div className="h-[120px] p-4 rounded-t-lg flex justify-between items-center px-[50px]">
                        <div>
                            <h1 className="text-xl font-bold text-[#707070]">
                                Stores
                            </h1>
                        </div>
                        <div>
                            {/* Toggle the visibility of Manage component */}
                            <button
                                className="custom-input px-4 py-2 bg-[#72BF45] w-[161px] h-38px text-white rounded-md"
                                onClick={handleOpenmanage}
                            >
                                +Add Store
                            </button>
                        </div>
                    </div>
                    <Subheader />
                    <Body
                        content={
                            <Table
                                rows={formattedRows}
                                columns={formattedColumns}
                                onEdit={handleEdit}
                                onDelete={handleDelete}
                            />
                        }
                    />
                </div>
            )}
        </div>
    );
};

export default List;
