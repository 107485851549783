import { FC } from 'react';
import Navbar from 'components/Navbar/Navbar';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Subheader from './Subheader';
import Body from './Body';
import './styles.css';
const Dashboard: FC = () => {
    return (
        <div className="flex h-screen w-full bg-[#f0f0f0]">
            <Navbar />
            <div
                className="flex-grow m-[25px] rounded-lg h-[95%] bg-white shadow-lg"
                style={{
                    maxWidth: 'calc(100% - 239px)',
                    borderRadius: '15px',
                    boxShadow: '0px 0px 25px #00000029'
                }}
            >
                <Outlet />
            </div>
        </div>
    );
};

export default Dashboard;
