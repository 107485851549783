import { useEffect, useState } from 'react';
import Subheader from '../Subheader';
import Table from '../Table/Table';
import Body from '../Body';
import Manage from './Manage';
import { _deletePost, _getPosts, _getCountries, Country } from 'routes/routes';
import { formatFlyerTable } from '../TableService';
import { useSnackbar } from 'notistack';
import useError from 'hooks/useError';
import { confirmDialog } from 'primereact/confirmdialog';
const statuses = [
    {
        id: 0,
        name: 'Pending'
    },
    {
        id: 1,
        name: 'Posted'
    },
    {
        id: 2,
        name: 'Active'
    }
];
const List: React.FC = () => {
    const setError = useError();
    const { enqueueSnackbar } = useSnackbar();
    const [updateId, setUpdateId] = useState<number | null>(null);
    const [data, setData] = useState<any[]>([]);
    const [newData, setNewData] = useState<any[]>([]);
    const [redrawTable, setRedrawTable] = useState<Boolean>(false);
    const [isOpenManage, setIsOpenManage] = useState(false);
    const [action, setAction] = useState<string>('New');
    const [status, setStatus] = useState<string>('-1');
    const [selectedCountry, setselectedCountry] = useState<string>('-1');
    const [countries, setCountries] = useState<Country[]>([]);
    useEffect(() => {
        if (!isOpenManage) {
            const fetchOptions = async () => {
                try {
                    const response = await _getPosts();
                    const countries = await _getCountries();
                    setCountries(countries.data);
                    setData(response.data);
                    setNewData(response.data);
                } catch (err) {
                    setError(err);
                }
            };
            setUpdateId(null);
            fetchOptions();
        }
    }, [isOpenManage, redrawTable]);
    const { formattedColumns, formattedRows } = formatFlyerTable(newData);
    const handleEdit = (id: number) => {
        // Logic to handle editing a row based on the provided ID
        // enqueueSnackbar('Item edited ', { variant: 'success' });
        setUpdateId(id);
        setAction('Edit');
        setIsOpenManage(true);
    };
    const handleOpenmanage = () => {
        setAction('New');
        setIsOpenManage(true);
    };
    // Function to handle deleting a row
    const handleDelete = async (id: number) => {
        const accept = async () => {
            try {
                await _deletePost(id);
                enqueueSnackbar('Item Deleted ', { variant: 'success' });
                setRedrawTable(!redrawTable);
            } catch (err) {
                setError(err);
            }
        };
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'accept',
            acceptClassName: 'p-button-danger',
            accept
        });
    };
    const handleStatusChange = async (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setStatus(event.target.value);
    };
    useEffect(() => {
        const filtered_data = data;
        if (status != '-1' && selectedCountry != '-1') {
            const new_data = filtered_data.filter(
                (item) =>
                    item.status == status && item.country.id == selectedCountry
            );
            setNewData(new_data);
        } else if (status != '-1' && selectedCountry == '-1') {
            const new_data = filtered_data.filter(
                (item) => item.status == status
            );
            setNewData(new_data);
        } else if (status == '-1' && selectedCountry != '-1') {
            const new_data = filtered_data.filter(
                (item) => item.country.id == selectedCountry
            );
            setNewData(new_data);
        } else if (status == '-1' && selectedCountry == '-1') {
            setNewData(data);
        } else {
            setNewData(data);
        }
    }, [status, selectedCountry]);
    const handleSelectChange = async (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setselectedCountry(event.target.value);
    };
    const selectoptions = (
        <div className="custom-select">
            <select
                id="options"
                className="select-countries custom-select"
                value={selectedCountry}
                onChange={handleSelectChange}
            >
                <option key={-1} value={-1}>
                    All Countries
                </option>
                {countries.map((country) => (
                    <option key={country.id} value={country.id}>
                        {country.name}
                    </option>
                ))}
            </select>
        </div>
    );
    const statusSelect = (
        <div className="custom-select">
            <select
                id="options"
                className="select-countries custom-select"
                value={status}
                onChange={handleStatusChange}
            >
                <option key={-1} value={-1}>
                    All Statuses
                </option>
                {statuses.map((status) => (
                    <option key={status.id} value={status.id}>
                        {status.name}
                    </option>
                ))}
            </select>
        </div>
    );
    return (
        <div className="h-full" style={{ height: '100%' }}>
            {isOpenManage ? (
                <div className="h-full">
                    <Manage
                        onClose={() => setIsOpenManage(false)}
                        onSave={() => setRedrawTable(!redrawTable)}
                        type={action}
                        updateId={updateId}
                    />
                </div>
            ) : (
                <div style={{ height: '100%' }}>
                    <div className="h-[120px] p-4 rounded-t-lg flex justify-between items-center px-[50px]">
                        <div>
                            <h1 className="text-xl font-bold text-[#707070]">
                                Flyers
                            </h1>
                        </div>
                        <div>
                            {/* Toggle the visibility of Manage component */}
                            <button
                                className="custom-input px-4 py-2 bg-[#72BF45] w-[161px] h-38px text-white rounded-md"
                                onClick={handleOpenmanage}
                            >
                                +Add Flyer
                            </button>
                        </div>
                    </div>
                    <Subheader
                        multiSelectComponent={selectoptions}
                        secondMultiSelect={statusSelect}
                        description="Filter Countries and Statuses"
                    />
                    <Body
                        content={
                            <Table
                                rows={formattedRows}
                                columns={formattedColumns}
                                onEdit={handleEdit}
                                onDelete={handleDelete}
                            />
                        }
                    />
                </div>
            )}
        </div>
    );
};

export default List;
