
import axios,  {AxiosRequestConfig} from 'axios';
import {TRequest} from "index.types";
export const request: TRequest = async (api, method, url, params = {}, token = null) => {
  try {
      const options: AxiosRequestConfig = {
          method: method,
          url: api + url,
          data: params,
          headers: {
              Authorization: `Bearer ${token}`
          }
      }

      const response = await axios(options);

      if (response.data.hasOwnProperty('body')) {
          return response.data.body;
      } else {
          return response.data;
      }
  } catch (error: any) {
      throw error;
  }
}
export const request_apikey: TRequest = async (api, method, url, params = {}, api_key, token = null) => {
    try {
        const options: AxiosRequestConfig = {
            method: method,
            url: api + url,
            data: params,
            headers: {
                Authorization: `Bearer ${token}`,
                'x-api-key': api_key

            }
        }
  
        const response = await axios(options);
  
        if (response.data.hasOwnProperty('body')) {
            return response.data.body;
        } else {
            return response.data;
        }
    } catch (error: any) {
        throw error;
    }
  }