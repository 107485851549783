import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from '@mui/material';

interface HeaderProps {
    type: string;
    loading?: boolean;
    handleClose: () => void;
    handleSubmit: () => void;
}

const Header: React.FC<HeaderProps> = ({
    type,
    handleClose,
    handleSubmit,
    loading = false
}) => {
    return (
        <div className="h-[120px] p-4 rounded-t-lg flex justify-between items-center px-[50px]">
            <div>
                <h1 className="f24 font-bold text-[#707070]">{type}</h1>
            </div>

            <div className="flex gap-1">
                <button
                    onClick={handleClose}
                    className="px-4 py-2 bg-[#ED1B25] w-[161px] text-white custom-input"
                >
                    Cancel
                </button>
                <button
                    onClick={handleSubmit}
                    disabled={loading ? true : false}
                    className="px-4 py-2 bg-[#72BF45] w-[161px] text-white custom-input flex justify-center items-center"
                >
                    {!loading ? 'Save' : <div className="loader"></div>}
                    {/* Save */}
                    {/* <CircularProgress size={20} color="primary" /> */}
                </button>
                {/* <LoadingButton
                    // size="small"
                    onClick={handleSubmit}
                    variant="contained"
                >
                    <span>Save</span>
                </LoadingButton> */}
            </div>
        </div>
    );
};

export default Header;
