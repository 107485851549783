import React, { useEffect } from 'react';
import {
    Button,
    Menu,
    MenuItem,
    Checkbox,
    FormControlLabel
} from '@mui/material';
import { FiChevronDown } from 'react-icons/fi';

interface Props {
    options: any;
    selectedOptions: string[];
    setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
    title: string;
    selectall?: boolean;
}

const MultiSelectCheckbox: React.FC<Props> = ({
    options,
    selectedOptions,
    setSelectedOptions,
    title,
    selectall
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const checkselectedoptions = () => {
        if (selectedOptions.length === 0 && selectall) {
            let newSelectedOptions = options.map(
                (option: any) => option.short_name
            );
            setSelectedOptions(newSelectedOptions);
        }
    };
    // useEffect(() => {
    //     if (!selectedOptions.length) {
    //         checkselectedoptions();
    //     }
    // }, [setSelectedOptions]);

    const handleCheckboxChange =
        (optionValue: string) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const isChecked = event.target.checked;
            let newSelectedOptions = [...selectedOptions];

            if (isChecked && !selectedOptions.includes(optionValue)) {
                newSelectedOptions.push(optionValue);
            } else if (!isChecked && selectedOptions.includes(optionValue)) {
                newSelectedOptions = selectedOptions.filter(
                    (value) => value !== optionValue
                );
            }
            setSelectedOptions(newSelectedOptions);
            // if(!newSelectedOptions.length){
            //     checkselectedoptions()
            // }
        };

    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        let newSelectedOptions = [...selectedOptions];

        if (isChecked) {
            newSelectedOptions = options.map(
                (option: any) => option.short_name
            );
        } else {
            newSelectedOptions = [];
        }

        setSelectedOptions(newSelectedOptions);
    };

    return (
        <div className="multi-select">
            <Button
                className="capitalize custom-input"
                style={{ justifyContent: 'space-between', height: '100%' }}
                aria-controls="demo-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                {title}
                <FiChevronDown />
            </Button>
            <Menu
                id="demo-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {selectall && (
                    <MenuItem>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        selectedOptions.length ===
                                        options.length
                                    }
                                    onChange={handleSelectAll}
                                />
                            }
                            label="Select All"
                        />
                    </MenuItem>
                )}
                {options.map((option: any) => (
                    <MenuItem key={option.short_name}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedOptions.includes(
                                        option.short_name
                                    )}
                                    onChange={handleCheckboxChange(
                                        option.short_name
                                    )}
                                />
                            }
                            label={option.name}
                        />
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default MultiSelectCheckbox;
