// Body.tsx
import React from 'react';

const columns = [
  { field: 'id', headerName: 'ID', width: 100 },
  { field: 'firstName', headerName: 'First Name', width: 150 },
  { field: 'lastName', headerName: 'Last Name', width: 150 },
  { field: 'age', headerName: 'Age', width: 100 },
  // Add more columns as needed
];

const rows = [
  { id: 1, firstName: 'John', lastName: 'Doe', age: 35 },
  { id: 2, firstName: 'Jane', lastName: 'Smith', age: 28 },
  // Add more rows as needed
];
type JSXProp = JSX.Element;
const Body: React.FC<{ content: JSXProp }> = ({content}) => {
  return (
    <div className="p-4" style={{height: 'calc(100% - 200px)'}}>
      {/* <Table  /> */}
      {content}
    </div>
  );
};

export default Body;
