import { useEffect, useState } from 'react';
import Subheader from '../Subheader';
import Table from '../Table/Table';
import Body from '../Body';
import Manage from './Manage';
import {
    _getProducts,
    _deleteProducts,
    _getCountries,
    Country
} from 'routes/routes';
import { formatProductTable } from '../TableService';
import { useSnackbar } from 'notistack';
import useError from 'hooks/useError';

import { confirmDialog } from 'primereact/confirmdialog';
const List: React.FC = () => {
    const setError = useError();
    const { enqueueSnackbar } = useSnackbar();
    const [updateId, setUpdateId] = useState<number | null>(null);
    const [data, setData] = useState<any[]>([]);
    const [newData, setNewData] = useState<any[]>([]);
    const [redrawTable, setRedrawTable] = useState<Boolean>(false);
    const [isOpenManage, setIsOpenManage] = useState(false);
    const [action, setAction] = useState<string>('New');
    const [selectedCountry, setselectedCountry] = useState<string>('-1');
    const [countries, setCountries] = useState<Country[]>([]);
    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const response = await _getProducts();
                const countries = await _getCountries();
                setCountries(countries.data);
                setData(response.data);
                setNewData(response.data);
            } catch (err) {
                setError(err);
            }
        };
        fetchOptions();
    }, [isOpenManage, redrawTable]);
    const { formattedColumns, formattedRows } = formatProductTable(newData);
    const handleEdit = (id: number) => {
        // Logic to handle editing a row based on the provided ID
        // enqueueSnackbar('Item edited ', { variant: 'success' });
        setUpdateId(id);
        setAction('Edit');
        setIsOpenManage(true);
    };
    const handleOpenmanage = () => {
        setAction('New');
        setIsOpenManage(true);
    };
    const handleCloseManage = () => {
        setUpdateId(null);
        setIsOpenManage(false);
    };

    // Function to handle deleting a row
    const handleDelete = async (id: number) => {
        const accept = async () => {
            try {
                await _deleteProducts(id);
                enqueueSnackbar('Item Deleted ', { variant: 'success' });
                setRedrawTable(!redrawTable);
            } catch (err) {
                setError(err);
            }
        };
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'accept',
            acceptClassName: 'p-button-danger',
            accept
        });
    };
    useEffect(() => {
        const filtered_data = data;
        if (selectedCountry != '-1') {
            const new_data = filtered_data.filter(
                (item) => item.product_prices[0].country.id == selectedCountry
            );
            setNewData(new_data);
        } else {
            setNewData(data);
        }
    }, [selectedCountry]);
    const handleSelectChange = async (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setselectedCountry(event.target.value);
    };
    const selectoptions = (
        <div className="custom-select">
            <select
                id="options"
                className="select-countries custom-select"
                value={selectedCountry}
                onChange={handleSelectChange}
            >
                <option key={-1} value={-1}>
                    All Countries
                </option>
                {countries.map((country) => (
                    <option key={country.id} value={country.id}>
                        {country.name}
                    </option>
                ))}
            </select>
        </div>
    );
    return (
        <div className="h-full" style={{ height: '100%', overflow: 'auto' }}>
            {isOpenManage ? (
                <div className="h-full">
                    <Manage
                        onClose={handleCloseManage}
                        onSave={() => setRedrawTable(!redrawTable)}
                        type={action}
                        updateId={updateId}
                    />
                </div>
            ) : (
                <div style={{ height: '100%' }}>
                    <div className="h-[120px] p-4 rounded-t-lg flex justify-between items-center px-[50px]">
                        <div>
                            <h1 className="text-xl font-bold text-[#707070]">
                                Product
                            </h1>
                        </div>
                        <div>
                            {/* Toggle the visibility of Manage component */}
                            <button
                                className="custom-input px-4 py-2 bg-[#72BF45] w-[161px] h-38px text-white rounded-md"
                                onClick={handleOpenmanage}
                            >
                                +Add Product
                            </button>
                        </div>
                    </div>
                    <Subheader multiSelectComponent={selectoptions} />
                    <Body
                        content={
                            <Table
                                rows={formattedRows}
                                columns={formattedColumns}
                                onEdit={handleEdit}
                                onDelete={handleDelete}
                            />
                        }
                    />
                </div>
            )}
        </div>
    );
};

export default List;
