import React, { useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    TextField,
    styled,
    InputAdornment
} from '@mui/material';
import { Country, _createProductDetails } from 'routes/routes';
import { flyer } from 'components/languages/product';
import moment from 'moment';

interface CustomModalProps {
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    title?: string;
    languages: any[];
}
const CustomTextField = styled(TextField)`
    & .MuiInputBase-root {
        height: 38px; // Adjust the height as needed
        border-radius: 10px;
        width: 100%;
        border: 1px solid #eeeeee;
        color: #969696;
    }
    & .MuiInputBase-root:hover,
    & .MuiInputBase-root.Mui-focused {
        border: none !important;
        border-width: 0px !important;
    }
`;
const CustomModal: React.FC<CustomModalProps> = ({
    open,
    onClose,
    onSave,
    title = 'Default Title',
    languages
}) => {
    const [languageInputs, setLanguageInputs] = useState<{
        [key: string]: string;
    }>({});
    const [languageInputsError, setLanguageInputsError] = useState<{
        [key: string]: boolean;
    }>({});
    const [loading, setLoading] = useState<boolean>(false);
    const handleInput2Change = (value: string, type: string) => {
        const trimmedValue = value.trimStart();
        setLanguageInputs((prevState) => ({
            ...prevState,
            [`${type}`]: trimmedValue
        }));
    };
    const handleSubmit = async () => {
        let tmp_params: any = {
            name: moment().valueOf().toString(),
            product_detail: []
        };
        languages.forEach((lang: any) => {
            tmp_params.product_detail.push({
                name: languageInputs[`productdetail-${lang.id}`],
                language_id: lang.id
            });
        });
        const validation = checkParams(tmp_params);
        if (validation.validation) {
            try {
                setLoading(true);
                const response = await _createProductDetails(tmp_params);
                setLoading(false);
                onSave();
                onClose();
            } catch (err) {
                setLoading(false);
            }
        }
    };
    const checkParams = (params: any) => {
        let isvalid = true;
        languages.forEach((lang: any) => {
            const value = languageInputs[`productdetail-${lang.id}`];
            if (value == '' || value == undefined) {
                setLanguageInputsError((prevState) => ({
                    ...prevState,
                    [`productdetail-${lang.id}`]: true
                }));
                isvalid = false;
            } else {
                setLanguageInputsError((prevState) => ({
                    ...prevState,
                    [`productdetail-${lang.id}`]: false
                }));
            }
        });
        if (!isvalid) {
            return {
                validation: false,
                msg: 'Please fill the required fields'
            };
        }
        return {
            validation: true,
            msg: ''
        };
    };
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            sx={{
                borderRadius: '10px'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '1px solid #6e6c6c',
                    borderRadius: '10px',
                    boxShadow: 24,
                    p: 4
                }}
            >
                <Typography id="modal-title" variant="h6" component="h2">
                    {title}
                </Typography>
                <Typography id="modal-description" sx={{ mt: 2 }}>
                    <div className="product-name flex flex-col gap-2">
                        {languages.map((language) => (
                            <>
                                <CustomTextField
                                    type="text"
                                    required
                                    error={
                                        languageInputsError[
                                            `productdetail-${language.id}`
                                        ]
                                    }
                                    placeholder={
                                        flyer.product_details[
                                            language.short_name as keyof typeof flyer.product_details
                                        ]
                                    }
                                    onChange={(e) =>
                                        handleInput2Change(
                                            e.target.value,
                                            `productdetail-${language.id}`
                                        )
                                    }
                                    style={{ background: '#F9FDF7' }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <div
                                                    className={`flag-${language.short_name}`}
                                                ></div>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </>
                        ))}
                    </div>
                </Typography>
                <Box className="flex justify-between mt-10">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-[#ED1B25] w-[161px] text-white custom-input"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSubmit}
                        disabled={loading ? true : false}
                        className="px-4 py-2 bg-[#72BF45] w-[161px] text-white custom-input flex justify-center items-center"
                    >
                        Save
                    </button>
                </Box>
            </Box>
        </Modal>
    );
};

export default CustomModal;
