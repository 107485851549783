// FlyerForm.tsx
import React, { useDebugValue, useEffect, useState } from 'react';
import {
    LocalizationProvider,
    DateTimePicker,
    renderTimeViewClock,
    DatePicker
} from '@mui/x-date-pickers';
import { DateValidationError } from '@mui/x-date-pickers/models';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import InputAdornment from '@mui/material/InputAdornment';
import FileUpload from 'components/Inputs/FileUpload';
import { Switch, TextField, alpha, styled } from '@mui/material';
import moment, { Moment } from 'moment';
import { flyer } from 'components/languages/product';
const CustomSwitch = styled(Switch)(() => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#72BF45',
        '&:hover': {
            backgroundColor: alpha('#72BF45', 0.2)
        }
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#72BF45'
    }
}));
const CustomTextField = styled(TextField)`
    & .MuiInputBase-root {
        height: 38px; // Adjust the height as needed
        border-radius: 10px;
        width: 100%;
        border: 1px solid #eeeeee;
        color: #969696;
    }
    & .MuiInputBase-root:hover,
    & .MuiInputBase-root.Mui-focused {
        border: none !important;
        border-width: 0px !important;
    }
`;

interface Language {
    short_name: string;
    country_language: {
        language_id: string;
    };
}

interface Country {
    languages: Language[];
}

interface TopFormProps {
    languageInputs: { [key: string]: string };
    handleInput2Change: (value: string, field: string) => void;
    label: object;
    checked: boolean;
    handleSwitchChange: any;
    handleFileUpload: any;
    imageName: string;
    startDate: any;
    minDate: any;
    endDate: any;
    handleStartDateChange: (date: Moment | null) => void;
    handleEndDateChange: (date: Moment | null) => void;
    handleDateTimeChange: (date: Moment | null) => void;
    activeDate: any;
    selectedCountryObj: Country[];
    languageInputsError: { [key: string]: boolean };
    type: String;
}

const Top: React.FC<TopFormProps> = ({
    languageInputs,
    handleInput2Change,
    label,
    checked,
    handleSwitchChange,
    handleFileUpload,
    imageName,
    minDate,
    startDate,
    endDate,
    handleStartDateChange,
    handleEndDateChange,
    handleDateTimeChange,
    activeDate,
    selectedCountryObj,
    languageInputsError,
    type
}) => {
    const [disablePostTime, setDisablePostTime] = useState<boolean>(false);
    useEffect(() => {
        if (moment().valueOf() > moment(activeDate).valueOf()) {
            setDisablePostTime(true);
        } else {
            setDisablePostTime(false);
        }
    }, [activeDate]);
    const [error, setError] = React.useState<DateValidationError | null>(null);
    const minDateTime = moment(moment().format('YYYY-MM-DD HH:mm:ss'));
    return (
        <div className="flex flex-col gap-1 pb-[25px]">
            <div className="product-name flex flex-col gap-2">
                <label>Flyer Id</label>
                <CustomTextField
                    type="text"
                    error={languageInputsError[`NAME`]}
                    required
                    placeholder="Flyer Id"
                    value={languageInputs[`NAME`] || ''}
                    onChange={(e) => handleInput2Change(e.target.value, 'NAME')}
                    style={{ background: '#F9FDF7' }}
                />
            </div>
            <div className="flex justify-between items-center">
                <label>Post flyer image</label>
                <div className="show-on-top">
                    <label>Show on top</label>
                    <CustomSwitch
                        {...label}
                        checked={checked}
                        onChange={handleSwitchChange}
                    />
                </div>
            </div>
            <FileUpload onFileUpload={handleFileUpload} img_name={imageName} />
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className="flex flex-col gap-2">
                    <label>Post date and time</label>
                    <div className="flex gap-2">
                        <DateTimePicker
                            label="Post Date and Time"
                            // minDate={moment()}
                            disabled={type == 'Edit' ? true : false}
                            className={`${
                                languageInputsError[`fromdate`]
                                    ? 'validation-error'
                                    : ''
                            }`}
                            closeOnSelect={false}
                            minDateTime={minDateTime}
                            value={activeDate}
                            viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock
                            }}
                            onChange={handleDateTimeChange}
                            ampm={false}
                            slotProps={{
                                textField: { size: 'small' }
                            }}
                            // sx={{ width: '100%', color: '#969696' }}
                        />
                        <DatePicker
                            label="End Date"
                            value={endDate}
                            minDate={startDate || minDate || moment()}
                            onChange={handleEndDateChange}
                            slotProps={{
                                textField: { size: 'small' }
                            }}
                            sx={{ color: '#969696' }}
                        />
                    </div>
                </div>
            </LocalizationProvider>
            <div className="flex flex-col">
                {selectedCountryObj.map((country) => (
                    <div
                        key={country.languages[0].country_language.language_id}
                        className="product-name flex flex-col gap-2"
                    >
                        <label>Post title</label>
                        {country.languages.map((language) => (
                            <>
                                <CustomTextField
                                    type="text"
                                    required
                                    error={
                                        languageInputsError[
                                            `title-${language.country_language.language_id}`
                                        ]
                                    }
                                    placeholder={
                                        flyer.name[
                                            language.short_name as keyof typeof flyer.name
                                        ]
                                    }
                                    value={
                                        languageInputs[
                                            `title-${language.country_language.language_id}`
                                        ] || ''
                                    }
                                    onChange={(e) =>
                                        handleInput2Change(
                                            e.target.value,
                                            `title-${language.country_language.language_id}`
                                        )
                                    }
                                    style={{ background: '#F9FDF7' }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <div
                                                    className={`flag-${language.short_name}`}
                                                ></div>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </>
                        ))}
                    </div>
                ))}
            </div>
            <div className="flex flex-col">
                {selectedCountryObj.map((country) => (
                    <div
                        key={country.languages[0].country_language.language_id}
                        className="product-name flex flex-col gap-2"
                    >
                        <label>Post Body</label>
                        {country.languages.map((language) => (
                            <>
                                <CustomTextField
                                    type="text"
                                    required
                                    placeholder={
                                        flyer.body[
                                            language.short_name as keyof typeof flyer.body
                                        ]
                                    }
                                    error={
                                        languageInputsError[
                                            `description-${language.country_language.language_id}`
                                        ]
                                    }
                                    value={
                                        languageInputs[
                                            `description-${language.country_language.language_id}`
                                        ] || ''
                                    }
                                    onChange={(e) =>
                                        handleInput2Change(
                                            e.target.value,
                                            `description-${language.country_language.language_id}`
                                        )
                                    }
                                    style={{ background: '#F9FDF7' }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <div
                                                    className={`flag-${language.short_name}`}
                                                ></div>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Top;
