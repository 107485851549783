// Button.tsx
import React from 'react';
import './styles.css'; // Import the button styles

interface ButtonProps {
    onClick: (e?: React.FormEvent) => Promise<void>;
    type?: "button" | "submit" | "reset";
    children: React.ReactNode;
  }
  
  const Button: React.FC<ButtonProps> = ({ onClick, type = "button", children }) => {
    return (
      <button className='button' type={type} onClick={onClick}>
        {children}
      </button>
    );
  };
  
  export default Button;