import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import _config from 'config';
import { _uploadimage } from 'routes/routes';
import { CircularProgress } from '@mui/material';
interface FileUploadProps {
    onFileUpload: (file: File, image_name: string) => void;
    img_name?: string;
    label?: string;
}

const FileUpload: React.FC<FileUploadProps> = ({
    onFileUpload,
    img_name,
    label
}) => {
    const [imageName, setImageName] = useState<string>(img_name || '');
    const [imgUri, setImgUri] = useState<URL>();
    const [imageLoading, setImageLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>('');
    useEffect(() => {
        if (img_name) {
            setImageName(img_name);
            const url = new URL(`${_config.s3_url}/${img_name.toString()}`);
            setImgUri(url);
        }
    }, [img_name]);
    const onDrop = async (acceptedFiles: File[]) => {
        setImageLoading(true);
        setErrorMsg('');
        try {
            const uploadedFile = acceptedFiles[0];
            const formData = new FormData();
            formData.append('image_file', uploadedFile);
            const response = await _uploadimage(formData);
            setImageName(response.data.image_name);
            onFileUpload(uploadedFile, response.data.image_name);
        } catch (err) {
            setImageLoading(false);
            setErrorMsg(
                'We can not upload your image!! Please make sure your image is under 5MB and is one of supported types.'
            );
        }
        setImageLoading(false);
        // You can perform additional file upload logic here if needed
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    return (
        <>
            <div className="flex items-center justify-center text-center z-20 text-red-600">
                {errorMsg}
            </div>
            <label>{label}</label>
            <div
                className="dragAndDropZone h-[256px] w-[450px] rounded border-4 border-dashed border-gray-400 border-opacity-50 bg-[#F9FDF7] flex justify-center items-center cursor-pointer relative"
                style={{
                    backgroundImage:
                        imageName != '' ? `url(${imgUri?.href})` : '',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                }}
                onMouseEnter={() => {
                    // Add onMouseEnter event to change color on hover
                    const browseSpan = document.getElementById('browse');
                    if (browseSpan) {
                        browseSpan.style.color = '#71BF45'; // Change color to green
                    }
                }}
                onMouseLeave={() => {
                    // Add onMouseLeave event to revert color when not hovered
                    const browseSpan = document.getElementById('browse');
                    if (browseSpan) {
                        browseSpan.style.color = '#969696'; // Revert color to default
                    }
                }}
            >
                {imageLoading && (
                    <div
                        className="showLoading h-full w-ful flex justify-center items-center flex-col gap-2 absolute"
                        style={{
                            zIndex: 1,
                            width: '100%',
                            background: '#f9fdf7'
                        }}
                    >
                        <CircularProgress color="success" />
                    </div>
                )}
                <div
                    {...getRootProps()}
                    className="h-full flex justify-center items-center flex-col gap-2"
                    style={{
                        opacity: imageName !== '' ? '0' : '1'
                    }}
                >
                    <input {...getInputProps()} />
                    <div>
                        <AddPhotoAlternateOutlinedIcon
                            sx={{
                                fontSize: 100,
                                color: '#71BF45'
                            }}
                        />
                    </div>
                    <div className="text-xl text-[#969696] font-bold">
                        Drag & drop files or <span id="browse">Browse</span>
                    </div>
                    <div className="text-md text-[#969696]">
                        Supported formates: JPEG, PNG, PDF not larger than 5MB
                    </div>
                </div>
            </div>
        </>
    );
};

export default FileUpload;
