import React from 'react';
import './styles.css'; 
import { JsxAttribute } from 'typescript';

interface InputProps {
  type: string;
  placeholder: string;
  name:string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: React.FC<InputProps> = ({ type, name, placeholder, value, onChange }) => {
  return (
    <input 
      type={type} 
      placeholder={placeholder} 
      value={value}
      name={name}
      onChange={onChange} 
      className="input" // Apply the input class
    />
  );
};

export default Input;
