// placeholders.ts
interface Product {
    SQ: string;
    MK: string;
}

interface ProductsMap {
    [key: string]: Product;
}

export const products: ProductsMap = {
    name: {
        SQ: 'Emri i produktit',
        MK: 'Ime na produktot'
    },
    price: {
        SQ: 'Cmimi i produktit',
        MK: 'Cena na produktot'
    },
    description: {
        SQ: 'Informata per produktin',
        MK: 'Informaci za produktot'
    }
};
export const flyer: ProductsMap = {
    name: {
        SQ: 'Titulli i kampanjes',
        MK: 'Наслов на кампанја'
    },
    body: {
        SQ: 'Kampanja fillon prej: ',
        MK: 'Кампанјава поцнува: '
    },
    valid_body: {
        SQ: 'Kampanja ka filluar',
        MK: 'Кампанјава поцнала'
    },
    product_details: {
        SQ: 'Title',
        MK: 'Title'
    }
};
