// LoginPage.tsx
import { FC, useState, useEffect } from 'react';
import {  useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth'

import Logo from './Logo';
import Input from 'components/Inputs/Input';
import Button from 'components/Inputs/Button';
import { _login } from 'routes/routes';
import './styles.css';
const LoginPage: FC = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/product";
  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');


  useEffect(() => {
      setErrMsg('');
  }, [user, pwd])
  const handleSubmit = async (e:any) => {
    e.preventDefault();

    try {
      if(user === '' || pwd === ''){

        setErrMsg("Username And Password can not be empty!");
      }else{
        const response = await _login({email:user, password:pwd})
        login(response.data.accessToken);
        setUser('');
        setPwd('');
        navigate(from, { replace: true });
      }
    } catch (err:any) {
        setErrMsg("login failed");
    }
}
  return (
    <div className="login-page">
      <div className="logo-container">
        <Logo color='#fff'/>
      </div>
      <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <Input 
            type="text" 
            placeholder="Username"
            name="username"
            value={user}
            onChange={(e) => setUser(e.target.value)}
          />
          <Input 
            type="password" 
            placeholder="Password" 
            value={pwd}
            name="password"
            onChange={(e) => setPwd(e.target.value)}
          />
          <Button onClick={(e) => handleSubmit(e)} type="submit">Submit</Button>

        </form>
      </div>
    </div>
  );
};

export default LoginPage;
