import { useEffect } from 'react';

const useBeforeUnload = (formChanged: boolean) => {
    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (formChanged) {
                e.preventDefault();
                e.returnValue = ''; // Required for some browsers
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [formChanged]);
};

export default useBeforeUnload;
