import {useCallback, useContext} from "react";
import {useSnackbar} from "notistack";
import { useAuth } from "./useAuth";

// import AuthContext from "context/Auth";
// import GlobalContext from "context/Global";

/**
 *  Custom Hook for error handling on API calls.
 **/
const useError = () => {
    const {enqueueSnackbar} = useSnackbar();
    const { logout } = useAuth();
    return useCallback((error: any) => {
        error = errorHandler(error);

        switch (error.status) {
            case 401:
                enqueueSnackbar('Unauthorized!', {variant: 'error'});
                logout();
                break;
            case 409:
                enqueueSnackbar(error.message, {variant: 'warning'});
                break;
            default:
                enqueueSnackbar(error.message, {variant: 'error'});
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps
}

export default useError;

const errorHandler = (error: any) => {
    if (error.response) {
        /**
         *  The request was made and the server responded with a status code
         *  that falls out of the range of 2xx
         **/
        console.error(error.response.data);
        return { //eslint-disable-line
            message: typeof error.response.data.error === 'string' ? error.response.data.error : (typeof error.response.data.error?.details === 'string' ? error.response.data.error?.details : (typeof error.response.data.details === 'string' ? error.response.data.details : 'Something went wrong')),
            status: error.response.status || 500
        }
    } else if (error.request) {
        /**
         *  The request was made but no response was received
         *  `error.request` is an instance of XMLHttpRequest in the browser and an instance of
         *  http.ClientRequest in node.js
         **/
        console.error(error.request);
        return { //eslint-disable-line
            message: 'Something went wrong!',
            status: 500
        }
    } else {
        /**
         *  Something happened in setting up the request that triggered an Error
         **/
        console.error('Error', error.message);
        return { //eslint-disable-line
            message: 'Something went wrong!',
            status: 500
        }
    }
}
