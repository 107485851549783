// Logo.tsx
import React from 'react';
// import { ReactComponent as Image } from 'components/assets/Logo.svg';
import { logoIcon } from 'components/assets/images';
interface LogoProps {
  color: string;
}
const Logo: React.FC<LogoProps> = ({color}) => {
  return (
    <div className="logo">
      {/* <img src="#" alt="Logo" /> */}
      {logoIcon(color)}
    </div>
  );
};

export default Logo;