// FlyerForm.tsx
import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import FileUpload from 'components/Inputs/FileUpload';
import { TextField, styled } from '@mui/material';
import { flyer } from 'components/languages/product';
import {
    DatePicker,
    DateTimePicker,
    renderTimeViewClock,
    LocalizationProvider
} from '@mui/x-date-pickers';
import { Moment } from 'moment';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const CustomTextField = styled(TextField)`
    & .MuiInputBase-root {
        height: 38px; // Adjust the height as needed
        border-radius: 10px;
        width: 100%;
        border: 1px solid #eeeeee;
        color: #969696;
    }
    & .MuiInputBase-root:hover,
    & .MuiInputBase-root.Mui-focused {
        border: none !important;
        border-width: 0px !important;
    }
`;

interface Language {
    short_name: string;
    country_language: {
        language_id: string;
    };
}

interface Country {
    languages: Language[];
}

interface TopFormProps {
    languageInputs: { [key: string]: string };
    handleInput2Change: (value: string, field: string) => void;
    handleFileUpload: any;
    imageName: string;
    selectedCountryObj: Country[];
    languageInputsError: { [key: string]: boolean };
    startDate: any;
    minDate: any;
    endDate: any;
    handleStartDateChange: (date: Moment | null) => void;
    handleEndDateChange: (date: Moment | null) => void;
}

const Bottom: React.FC<TopFormProps> = ({
    languageInputs,
    handleInput2Change,
    handleFileUpload,
    imageName,
    selectedCountryObj,
    languageInputsError,
    minDate,
    startDate,
    endDate,
    handleStartDateChange,
    handleEndDateChange
}) => {
    return (
        <div className="flex flex-col gap-1">
            <div className="flex justify-between items-center">
                <label>Valid time flyer image</label>
            </div>
            <FileUpload onFileUpload={handleFileUpload} img_name={imageName} />
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className="flex flex-col gap-2">
                    <label>Valid days</label>
                    <div className="flex width-full">
                        <DateTimePicker
                            label="Start Date"
                            value={startDate}
                            className={`${
                                languageInputsError[`valid-date`]
                                    ? 'validation-error'
                                    : ''
                            }`}
                            closeOnSelect={false}
                            ampm={false}
                            viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock
                            }}
                            onChange={handleStartDateChange}
                            minDateTime={minDate}
                            slotProps={{
                                textField: { size: 'small' }
                            }}
                            sx={{ width: '100%', color: '#969696' }}
                        />
                    </div>
                </div>
            </LocalizationProvider>
            <div className="flex flex-col">
                {selectedCountryObj.map((country) => (
                    <div
                        key={country.languages[0].country_language.language_id}
                        className="product-name flex flex-col gap-2"
                    >
                        <label>Valid time title</label>
                        {country.languages.map((language) => (
                            <>
                                <CustomTextField
                                    type="text"
                                    required
                                    error={
                                        languageInputsError[
                                            `v-title-${language.country_language.language_id}`
                                        ]
                                    }
                                    placeholder={
                                        flyer.name[
                                            language.short_name as keyof typeof flyer.name
                                        ]
                                    }
                                    value={
                                        languageInputs[
                                            `v-title-${language.country_language.language_id}`
                                        ] || ''
                                    }
                                    onChange={(e) =>
                                        handleInput2Change(
                                            e.target.value,
                                            `v-title-${language.country_language.language_id}`
                                        )
                                    }
                                    style={{ background: '#F9FDF7' }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <div
                                                    className={`flag-${language.short_name}`}
                                                ></div>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </>
                        ))}
                    </div>
                ))}
            </div>
            <div className="flex flex-col">
                {selectedCountryObj.map((country) => (
                    <div
                        key={country.languages[0].country_language.language_id}
                        className="product-name flex flex-col gap-2"
                    >
                        <label>Valid time Body</label>
                        {country.languages.map((language) => (
                            <>
                                <CustomTextField
                                    type="text"
                                    required
                                    placeholder={
                                        flyer.valid_body[
                                            language.short_name as keyof typeof flyer.valid_body
                                        ]
                                    }
                                    error={
                                        languageInputsError[
                                            `v-description-${language.country_language.language_id}`
                                        ]
                                    }
                                    value={
                                        languageInputs[
                                            `v-description-${language.country_language.language_id}`
                                        ] || ''
                                    }
                                    onChange={(e) =>
                                        handleInput2Change(
                                            e.target.value,
                                            `v-description-${language.country_language.language_id}`
                                        )
                                    }
                                    style={{ background: '#F9FDF7' }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <div
                                                    className={`flag-${language.short_name}`}
                                                ></div>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Bottom;
